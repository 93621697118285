.dropzone {
  height: calc(100vh - 64px - 16px - 64px);
  border: dashed 2px #999;
  box-sizing: border-box;
  margin: 8px;
  padding: 8px;
}

.uploading {
  background-color: #ddd;
}

.inputFileHide {
  opacity: 0;
  appearance: none;
  position: absolute;
}

.confirmButton {
  margin-top: 32px;
  margin-left: 16px;
}
